
import { defineComponent, ref } from "vue"
import { ErrorMessage, Field, Form } from "vee-validate"
import * as Yup from "yup"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import store from "@/store"
import { Mutations } from "@/store/enums/StoreEnums"
import { mapGetters } from "vuex"
import { displayStatus } from "@/core/helpers/displayStatus"
import { randomString } from "@/core/helpers/randomString"

export default defineComponent({
  name: "GoogleAnalytics",
  components: {
    ErrorMessage,
    Field,
    Form
  },
  emits: ["throwError"],
  props: {
    connection: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const submitUpdateGoogleAnalyticsRequest = ref<HTMLElement | null>(null)
    let connectionRef = ref<any>({
      analytics_email: props.connection.analytics_email ?? "",
      analytics_ua: props.connection.analytics_ua ?? "",
      analytics_view_id: props.connection.analytics_view_id ?? "",
      analytics_dimension_filter: props.connection.analytics_dimension_filter ?? "",
      property_id: props.connection.property_id ?? "",
    })

    const updateGoogleAnalyticsFormValidator = Yup.object().shape({
      analytics_ua: Yup.string().matches(/^(\d+)-(\d+)$/, {
        message: "'UA-' is not needed, only the number (ex. '123456789-1')",
        excludeEmptyString: true
      }).label("123456789-1"),
      analytics_email: Yup.string().email().required().label("Analytics Email"),
      analytics_view_id: Yup.string().label("Analytics View ID"),
      analytics_dimension_filter: Yup.string().label("Analytics Dimension Filter"),
      property_id: Yup.string().required().label("GA4 Property ID")
    })

    return {
      connectionRef,
      submitUpdateGoogleAnalyticsRequest,
      updateGoogleAnalyticsFormValidator
    }
  },
  computed: {
    ...mapGetters(["activeAccountInfo"]),
  },
  methods: {
    async sendRequest() {
      ApiService.setHeader()

      // @TODO update when API completed
      let resp
      try {
        resp = await ApiService.put(`/accounts/${this.activeAccountInfo.id}`, { data: { ...this.connectionRef } })
      } catch (e) {
        console.error("Problem updating account google analytics", e)
      }

      if (resp.data.data) {
        store.commit(Mutations.UPDATE_ACTIVE_ACCOUNT_DETAIL, resp.data.data.attributes)
        return {
          status: 200,
          message: "Update Google Analytics successful",
          status_check: resp.data.meta.status_check
        }
      } else {
        this.$emit("throwError", {
          errorMessage: "unable to store google analytics info",
          errorToastId: randomString()
        })
      }

    },
    async saveGoogleAnalytics() {
      if (this.submitUpdateGoogleAnalyticsRequest) {
        // Activate indicator
        this.submitUpdateGoogleAnalyticsRequest.setAttribute("data-kt-indicator", "on")

        const response = await this.sendRequest()

        if (response && response.status == 200) {
          await Swal.fire({
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitUpdateGoogleAnalyticsRequest.removeAttribute("data-kt-indicator")
          await displayStatus(response.status_check)
          // emit("refreshLocations")
        } else {
          Swal.fire({
            text: "Either the save or connection status is bad..... tbd above",
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitUpdateGoogleAnalyticsRequest.removeAttribute("data-kt-indicator")
        }
      }
    },
  }
});
