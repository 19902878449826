
import { defineComponent, ref } from "vue"
import { ErrorMessage, Field, Form } from "vee-validate"
import * as Yup from "yup"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import store from "@/store"
import { Mutations } from "@/store/enums/StoreEnums"
import { mapGetters } from "vuex"

export default defineComponent({
  name: "GoHighLevel",
  props: {
    connection: {
      type: Object,
      required: true
    }
  },
  emits: ['refreshData'],
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup(props) {
    const submitUpdateGoHighLevelRequest = ref<HTMLElement | null>(null)
    let connectionRef = ref<any>({
      client_id: props.connection.client_id ?? "",
      client_secret: props.connection.client_secret ?? ""
    })

    const updateGoHighLevelFormValidator = Yup.object().shape({
      client_id: Yup.string().required().label("Client ID"),
      client_secret: Yup.string().required().label("Client Secret")
    })

    return {
      submitUpdateGoHighLevelRequest,
      updateGoHighLevelFormValidator,
      connectionRef
    }
  },
  computed: {
    ...mapGetters(["activeAccountInfo"]),
  },
  methods: {
    async sendRequest() {
      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.delete(`/accounts/${this.activeAccountInfo.id}/go-high-level/${this.connection.id}`)
      } catch (e) {
        console.error("Problem deleting Go High Level resource", e)
      }

      // update mutation for relationship info
      if (resp.status == 200) {
        store.commit(Mutations.REMOVE_ACTIVE_ACCOUNT_RELATIONSHIP, {
          name: "goHighLevels",
          data: { id: this.connection.id }
        })

        // remove GHL integration
        store.commit(Mutations.SET_GHL_INTEGRATION, {})
        this.$emit("refreshData")
        return { status: 200 }
      } else {
        // this.errors.push("unable to remove Go High Level info")
      }

    },
    async deleteGoHighLevel() {
      if (this.submitUpdateGoHighLevelRequest) {
        // Activate indicator
        this.submitUpdateGoHighLevelRequest.setAttribute("data-kt-indicator", "on")

        let sResp = await Swal.fire({
          text: "Are you sure you want to delete this connection",
          icon: "question",
          confirmButtonText: "Ok",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          buttonsStyling: false,
          reverseButtons: true,
          customClass: {
            confirmButton: "btn btn-light-primary",
            cancelButton: "btn btn-danger me-3"
          }
        })

        if (sResp.isConfirmed) {
          const response = await this.sendRequest()

          if (response && response.status == 200) {
            await Swal.fire({
              text: "Successfully deleted Go High Level Connection",
              icon: "success",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary"
              }
            })
          } else {
            Swal.fire({
              text: "Couldn't delete the Go High Level Connection",
              icon: "error",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary"
              }
            })
            this.submitUpdateGoHighLevelRequest.removeAttribute("data-kt-indicator")
          }

        } else {
          this.submitUpdateGoHighLevelRequest.removeAttribute("data-kt-indicator")
        }

      }
    },
  }
});
