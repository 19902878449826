
import { defineComponent, ref } from "vue"
import { ErrorMessage, Field, Form } from "vee-validate"
import * as Yup from "yup"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import store from "@/store"
import { Mutations } from "@/store/enums/StoreEnums"
import { mapGetters } from "vuex"
import { displayStatus } from "@/core/helpers/displayStatus"

export default defineComponent({
  name: "AgencyAnalytics",
  props: {
    connection: {
      type: Object,
      required: true
    }
  },
  components: {
    ErrorMessage,
    Field,
    Form
  },
  setup(props) {
    const submitUpdateAgencyAnalyticsRequest = ref<HTMLElement | null>(null)
    let connectionRef = ref<any>({
      campaign_id: props.connection.campaign_id ?? "",
      friendly_name: props.connection.friendly_name ?? ""
    })

    const updateAgencyAnalyticsFormValidator = Yup.object().shape({
      campaign_id: Yup.string().required().label("Campaign ID"),
      friendly_name: Yup.string().label("Friendly Name")
    })

    return {
      submitUpdateAgencyAnalyticsRequest,
      updateAgencyAnalyticsFormValidator,
      connectionRef
    }
  },
  computed: {
    ...mapGetters(["activeAccountInfo"]),
  },
  methods: {
    async sendRequest() {
      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.put(`/accounts/${this.activeAccountInfo.id}/agency-analytics/${this.connection.id}`, { data: { ...this.connectionRef } })
      } catch (e) {
        console.error("Problem updating agency analytics", e)
      }

      if (resp.data.data) {
        store.commit(Mutations.UPDATE_ACTIVE_ACCOUNT_RELATIONSHIP, {
          name: "agencyAnalytics",
          data: { id: resp.data.data.id, ...resp.data.data.attributes }
        })
        // @TODO validate connection status maybe?
        return { status: 200, message: "Update successful", meta: resp.data.meta }
      } else {
        // this.errors.push("unable to store google analytics info")
      }

    },
    async saveAgencyAnalytics() {
      if (this.submitUpdateAgencyAnalyticsRequest) {
        // Activate indicator
        this.submitUpdateAgencyAnalyticsRequest.setAttribute("data-kt-indicator", "on")

        const response = await this.sendRequest()

        if (response && response.status == 200) {
          await Swal.fire({
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitUpdateAgencyAnalyticsRequest.removeAttribute("data-kt-indicator")
          await displayStatus(response.meta.status_check)
        } else {
          Swal.fire({
            text: "Either the save or connection status is bad..... tbd above",
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitUpdateAgencyAnalyticsRequest.removeAttribute("data-kt-indicator")
        }
      }
    },
  }
});
